@import '../../styles/variables';

.coin-card {
	color: $light-grey;
	font-family: $font-main;
	background-color: $black-blue;
	opacity: 1;
	transition: opacity 2s;

	&.loading {
		opacity: 0.7;
		.coin-title {
			span {
				cursor: auto;
				animation: spin 0.5s infinite linear;
				opacity: 0.5;
			}
		}
	}

	@keyframes spin {
		from {transform:rotate(0deg);}
		to {transform:rotate(360deg);}
	}

	.coin-title {
		padding: $padding-small;
		img, p {
			display: inline-block;
			margin: 0;
		}
		img {
			margin-right: $margin-small;
			position: relative;
			top: 7px;
		}

		p {
			font-size: 30px;
			font-weight: 700;
			text-transform: uppercase;
		}

		span {
			float: right;
			cursor: pointer;
			font-size: 20px;
		}
	}
	
	.exchanges {
		background-color: $dark-grey;
	}
}