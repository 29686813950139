@import '../../styles/variables';
* {
	box-sizing: border-box;
}
body {
	margin: 0;
	background-color: $darkest-blue;
}

.loading {
	width: 100%;
	height: 300px;
	background-color: $black-blue;
}

.app {
	.container {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		padding: $padding-small;

		.title {
			h1 {
				font-family: $font-main;
				font-weight: 700;
				font-size: 40px;
				margin: 0;
				color: $light-grey;
			}
		}

		.error {
			padding: $padding-small;
			background-color: $cool-red;
			width: 100%;
			p {
				margin: 0;
				font-family: $font-main;
				font-size: 20px;
				color: $light-grey;

				span {
					text-decoration: underline;
					cursor: pointer;
					margin-left: $margin-small;
				}
			}
		}

		.coins {
			width: 100%;
			.coin-card {
				display: block;
				margin-bottom: $margin-medium;

				@media (min-width: $screen-tablet){
					display: inline-block;
					width: 45%;
					vertical-align: top;

					&:last-child {
						margin-left: 5%;
					}
				}
			}
		}
	}
}