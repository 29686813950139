@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

// colors
$dark-blue: #29283f;
$darkest-blue: #1c1b28;
$black-blue: #0f0e15;
$dark-grey: #23272a;
$light-grey: rgb(246,246,246);
$cool-green:#00cc83;
$cool-red: #d42f5f;

// padding
$padding-very-small: 5px;
$padding-small: 10px;
$padding-medium: 30px;

// margin
$margin-small: 10px;
$margin-medium: 20px;

// font
$font-main: 'DM Sans', sans-serif;
$font-copy: 'DM Sans', sans-serif;

// screens
$screen-tablet: 700px;