@import '../../styles/variables';

.exchange {
	background-color: $dark-blue;
	padding: $padding-medium;
	padding-top: $padding-small;
	transition: background-color 0.08s;
	cursor: pointer;
	font-size: 20px;
	border-bottom: solid 2px $darkest-blue;

	.title {
		display: flex;
		justify-content: space-between;
		p {
			font-weight: 700;
			font-size: 25px;
			margin: 0;
			text-transform: capitalize;
		}
		.recommend {
			.buy, .sell {
				display: inline-block;
				transition: opacity .2s;
			}
			.buy {
				color: $cool-green;
				margin-right: $margin-small;
			}
			.sell {
				color: $cool-red;
			}
			padding: $padding-very-small;
			border-radius: 5%;
			p {
				// font-size: 17px;
				color: $darkest-blue;
			}
		}
	}
	.prices {
		p {
			font-family: $font-copy;

		}
	}

	&:hover {
		background-color: $darkest-blue;
	}

	
}